<template>
  <div class="data-box">
    <van-nav-bar
        title="用餐详情"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">负责人</span>
          <span class="span-name align-right">{{ mealsInfo.name ? mealsInfo.name : "无" }}</span>
          <a :href="'tel:' + mealsInfo.phone" class="iconfont icon-dianhua1" v-show="mealsInfo.phone"></a>
        </div>
      </div>
      <div class="body-div" v-show="mealsList.length > 0">
        <div class="body-div-title align-center">
          用餐安排
        </div>
        <div class="body-div-tables">
          <dl>
            <tr>
              <dt>餐点类别</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["早餐","午餐","晚餐","宵夜"][item.diningType]}}
              </dd>
            </tr>
            <tr>
              <dt>用餐方式</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{["不安排","派餐","餐补","堂食","客户"][item.arrangeType]}}
              </dd>
            </tr>
            <tr>
              <dt>餐标价格</dt>
              <dd v-for="item in mealsList" :key="item.diningType">
                {{'￥' + item.dealNorm}}
              </dd>
            </tr>
          </dl>
        </div>
        <div v-show="mealsAddressList.length > 0">
          <div class="solid-line"></div>
          <div v-for="(item,index) in mealsAddressList" :key="index">
            <div class="body-div-item">
              <div class="span-key">堂食餐厅</div>
              <div class="span-value">{{ item.objName ? item.objName : "无"}}</div>
            </div>
            <div class="body-div-item">
              <div class="span-key">餐厅地址</div>
              <div class="span-value">{{ item.phoneSite ? item.phoneSite : "无"}}</div>
            </div>
            <div class="dashed-line" v-show="index !== (mealsAddressList.length - 1)"></div>
          </div>
          <div class="solid-line"></div>
        </div>
        <div class="body-div-item">
          <div class="span-key">用餐周期</div>
          <div class="span-value">{{ mealsInfo.mealsCycle ? mealsInfo.mealsCycle : "无" }}</div>
        </div>
      </div>
      <div class="body-div" v-show="mealsArrangeList.length > 0">
        <div class="body-div-title align-center">
          用餐记录
        </div>
        <div class="body-div-table">
          <dl>
            <tr>
              <dt>用餐日期</dt>
              <dt>用餐餐点</dt>
              <dt>用餐方式</dt>
              <dt>确认人</dt>
            </tr>
            <tr v-for="item in mealsArrangeList" :key="item.diningType">
              <dd>{{ $tool.dateFormat(new Date(item.createTime)) }}</dd>
              <dd>
                {{["早餐","午餐","晚餐","宵夜"][item.hotelDiningId]}}
              </dd>
              <dd>
                {{item.type === 4 ? '派餐' : '堂食'}}
              </dd>
              <dd>
                {{item.handlePerson}}&nbsp;<a :href="'tel:' + item.handlePhone" class="iconfont icon-dianhua1"></a>
              </dd>
            </tr>
          </dl>
        </div>
      </div>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  foodObjShow
} from "@/api/index";

export default {
  name: "orgCheck_meals",
  data() {
    return {
      mealsInfo: {
        name: "",
        phone: ""
      },
      mealsAddressList: [],
      mealsList: [],
      mealsArrangeList: []
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getSelectOPDetails.activitySets;
      activitySets.forEach(item => {
        if(item.relateType === 3){
          that.mealsInfo.name = item.chargeName;
          that.mealsInfo.phone = item.chargePhone;
        }
      })
      that.getMealsInfo();
    },
    //获取餐食信息
    getMealsInfo(){
      let that = this;
      let params = {
        ayId : that.$store.getters.getSelectOPDetails.id
      };
      foodObjShow(params).then(res => {
        that.$tool.resValidation(res, () => {
          let data = res.data;
          that.mealsInfo.mealsCycle = data.actStartDate + " ~ " + data.actEndDate;
          that.mealsList = data.activityFoodSets;
          that.mealsAddressList = data.activityRelateObjs;
          that.mealsArrangeList = data.aoscsList;
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    toCheckItem(item){
      let that = this;
      that.$router.push(item.router);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>